import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius, zIndexes } from '~/styles/constants'

const COLOR_CREAM = '#F0F6FF'

export const mockupPhoneApp = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  pointer-events: none;
  user-select: none;
`

export const appContainer = css`
  position: absolute;
  width: 90%;
  height: 95%;
  background-color: ${COLOR_CREAM};
  border-radius: 3rem;
  overflow: hidden;
  z-index: ${zIndexes.under};
`

export const appHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6rem;
  margin-bottom: 5rem;
  font-size: 1.75rem;
  padding: 0 1.5rem;
  font-weight: 600;
`

export const productSubtitle = css`
  text-align: center;
  font-size: 1.15rem;
  font-weight: 300;
`

export const productShowcase = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  > img:nth-child(1), img:nth-child(3) {
    position: absolute;
    width: 40%;
  }

  > img:nth-child(1) {
    top: 5%;
    left: -26%;
  }

  > img:nth-child(2) {
    width: 50%;
  }

  > img:nth-child(3) {
    top: 0;
    right: -26%;
  }
`

export const productDetailsContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${colors.white};
  height: 23%;
  padding: 1rem;
  margin-top: 5rem;
`

export const productDetailsOutter = css`
  position: relative;
  display: flex;
  align-items: flex-start;

  > img {
    width: 20%;
  }
`

export const iconFavoriteProduct = css`
  position: absolute;
  top: 0rem;
  right: 0rem;
  font-size: 1.75rem;
  color: ${colors.red};
`

export const productDetails = css`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`

export const productTitle = css`
  font-size: 1.2rem;
  font-weight: 500;
`

export const productReview = css`
  display: flex;
  align-items: center;
  color: ${colors.gray};

  > #divz-icon {
    font-size: 1.25rem;
    color: ${colors.yellow};
    margin-right: 0.25rem;
  }

  > span {
    font-weight: 600;
    margin-right: 0.25rem;
  }

  > small {
    font-size: 0.75rem;
  }
`

export const productDescription = css`
  width: 90%;
  margin: auto;
  margin-top: 1.5rem;
  background-color: ${colors.gray};
  color: ${colors.white};
  font-size: 0.9rem;
  border-radius: ${borderRadius};
  padding: 0.75rem;
`

export const appFooter = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  width: 86%;
  height: 5rem;
  padding: 0 2rem;
  border-bottom-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  background: ${colors.white};
  color: ${transparentize(0.65, colors.gray)};
  font-size: 2rem;

  > #divz-icon:first-child {
    color: ${colors.black};
  }
`
