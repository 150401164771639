import { css } from '@emotion/react'
import { lighten } from 'polished'

import colors from '~/styles/colors'
import { borderRadius } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'

const PADDING = '1.5rem'

export const mockupRetention = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(17.5rem - ${PADDING} * 2);
  height: calc(20rem - ${PADDING} * 2);
  border-radius: ${borderRadius};
  padding: ${PADDING};
  background: linear-gradient(0deg, ${lighten(0.15, colors.orange)} 0%, ${colors.white} 100%);
  ${boxShadow};
  user-select: none;
`

export const headerBar = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const iconInfo = css`
  color: ${colors.blue};
  font-size: 1.5rem;
`

export const chartContainer = css`
  position: relative;
  height: 65%;
  width: 65%;
`

export const chartValue = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 2rem;
  font-weight: 400;
`

export const cardTitle = css`
  font-size: 1.2rem;
  font-weight: 600;
`

export const cardSubtitle = css`
  color: ${colors.gray};
  text-transform: uppercase;
  letter-spacing: 0.15rem;
`

export const cardMeta = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
