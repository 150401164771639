import React from 'react'

import { transparentize } from 'polished'
import { Line } from 'react-chartjs-2'

import colors from '~/styles/colors'

let width, height, gradient
const getLineGradient = (ctx, chartArea) => {
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(0, colors.transparent)
    gradient.addColorStop(0.1, transparentize(0.95, colors.green))
    gradient.addColorStop(1, colors.green)
  }

  return gradient
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: {
      display: false,
      min: 100,
    },
    xAxes: {
      display: false,
    },
  },
  plugins: {
    legend: false,
  },
  tension: 0.3,
}


const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
  datasets: [
    {
      label: 'Site hits',
      data: [177, 195, 187, 209, 199, 242, 224, 287, 301],
      fill: 'origin',
      backgroundColor(context) {
        const chart = context.chart
        const { ctx, chartArea } = chart

        if (!chartArea) { return null }

        return getLineGradient(ctx, chartArea)
      },
      borderColor: colors.green,
    },
  ],
}

const AreaChart = () => (
  <Line data={data} options={options} />
)

export default AreaChart
