// REF: https://usehooks.com/useWindowSize/

import { useState, useEffect } from 'react'

const debounce = (callback, wait = 500) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { callback.apply(this, args) }, wait)
  }
}

export default () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = debounce(() => setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    }))

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
