import React, { useState, useEffect } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'

import prefersReducedMotion from '~/utils/prefersReducedMotion'

import SplitText from './SplitText'

const DELAY = 175

/**
 * <Describe the Animation component here>
 *
 * @component
 * @usage import AnimationTextPopIn from '~/components/Animation/TextPopIn'
 * @example
 * <Add an example of how Animation is used here>
 */
const AnimationTextPopIn = ({ children, isVisible }) => {
  if (prefersReducedMotion) {
    return children
  }

  const [isElementVisible, setIsElementVisible] = useState(isVisible)

  useEffect(() => {
    if (isVisible !== isElementVisible) {
      setIsElementVisible(isVisible)
    }
  }, [isVisible])

  return (
    <AnimatePresence>
      {
        isElementVisible && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SplitText
              initial={{ y: '100%' }}
              animate='visible'
              variants={{
                visible: i => ({
                  y: 0,
                  transition: {
                    delay: i * (DELAY / 1000),
                  },
                }),
              }}
            >
              {children}
            </SplitText>
          </motion.div>
        )
      }
    </AnimatePresence>
  )
}

AnimationTextPopIn.propTypes = {
  isVisible: PropTypes.bool,
}

AnimationTextPopIn.defaultProps = {
  isVisible: true,
}

export default AnimationTextPopIn
