import { css } from '@emotion/react'
import { lighten, transparentize, darken } from 'polished'

import colors from '~/styles/colors'
import boxShadow from '~/styles/mixins/boxShadow'
import { mqMax } from '~/styles/mixins/mediaQueries'

const PADDING = '1.5rem'
const CARD_COLOR = colors.purple

export const mockupCreditCard = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(22rem - ${PADDING} * 2);
  height: calc(13rem - ${PADDING} * 2);
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, ${transparentize(0.65, CARD_COLOR)} 1rem), repeating-linear-gradient(${darken(0.25, CARD_COLOR)}, ${colors.black});
  border-radius: 1rem;
  padding: ${PADDING};
  ${boxShadow};
  color: ${colors.white};
  font-family: 'Courier Prime', monospace;
  user-select: none;
`

export const refreshButton = css`
  position: absolute;
  left: -4.5rem;
  top: 0%;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0;

  ${mqMax.lg} {
    left: -5rem;
    font-size: 1.5rem;
  }
`

export const creditCardHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const iconSimChip = css`
  font-size: 3rem;
`

export const imageLogoVisa = css`
  width: 5rem;
`

export const creditCardNumber = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
`

export const creditCardInfo = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const creditCardInfoBlock = css`
  display: flex;
  flex-direction: column;

  > small {
    color: ${lighten(0.25, CARD_COLOR)}
  }
`
