import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionSummary = css`
  position: relative;
  background: ${colors.backgroundDivzGradient};
  overflow: hidden;
  margin: 5rem 0;

  .content-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    margin: auto;
    max-width: 80rem;
    
    ${mqMax.md} {
      padding: 0 1rem;
    };
  }
`

export const summary = css`
  font-size: 2.25rem;
  text-align: center;
`

export const icon = css`
  color: ${colors.divzBlue};
  font-size: 3.5rem;
  margin-bottom: -1rem;
`
