import { css, keyframes } from '@emotion/react'

import colors from '~/styles/colors'

const transform = keyframes`
  0% {
    border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
    background: linear-gradient(45deg, ${colors.divzBlue} 0%, ${colors.divzGreen} 100%);
  } 
  
  50% {
    border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
    background: linear-gradient(45deg, ${colors.divzBlueGreen} 0%, ${colors.divzGreen} 100%);
  }
  
  100% {
    border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
    background: linear-gradient(45deg, ${colors.divzBlue} 0%, ${colors.divzGreen} 100%);
  } 
`

const movement = keyframes`
  0%,
  50% { transform: rotate(360deg); }
  100% { transform: none; }
`


export default css`
  animation:
    ${transform} 60s ease-in-out infinite both alternate,
    ${movement} 50s ease-in-out infinite both;
`
