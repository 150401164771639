import { css } from '@emotion/react'

import gradientText from '~/styles/mixins/gradientText'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const sectionTitleContainer = css`
  position: relative;
  margin-top: 4rem;

  ${mqMax.lg} {
    text-align: center;
  }
`

export const sectionCode = css`
  position: absolute;
  top: 0;
  left: 0;
  ${gradientText};

  ${mqMax.lg} {
    right: 0;
  }
`

export const sectionTitle = css`
  font-size: 2.25rem;
  padding-top: 1.75rem;
  line-height: 2.5rem;
`
