import React from 'react'

import MockupBlob from '~/components/MockupBlob'
import ScrollReveal from '~/components/ScrollReveal'

import LaptopApp from '../LaptopApp'
import MockupWrapper from '../MockupWrapper'
// todo: Mockups need CLEANUP
import PhoneApp from '../PhoneApp'

import * as style from './styles'

const MOCKUP_WIDTH = 545
const SCALES = {
  LAPTOP: 0.5,
  MOBILE: 0.3,
}

const MockupApps = ({ rtl, ...props }) => {
  const revealDirection = rtl ? 'right' : 'left'

  return (
    <MockupWrapper width={MOCKUP_WIDTH} scales={SCALES}>
      {
        (scales) => (
          <div data-nosnippet css={style.mockApps(`${MOCKUP_WIDTH}px`)} {...props}>
            <ScrollReveal direction={undefined}>
              <MockupBlob id='1' />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} css={style.mockupLaptopApp(scales.LAPTOP)}>
              <LaptopApp />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={200} css={style.mockupPhoneApp(scales.MOBILE)}>
              <PhoneApp />
            </ScrollReveal>
          </div>
        )
      }
    </MockupWrapper>
  )
}

export default MockupApps
