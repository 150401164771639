import React, { Fragment } from 'react'

import Meta from '~/components/Meta'

import SectionCTA from './sections/CTA'
import SectionHowItWorks from './sections/HowItWorks'
import SectionIntro from './sections/Intro'
import SectionPartners from './sections/Partners'
import SectionServices from './sections/Services'
import SectionSummary from './sections/Summary'

const HomePage = () => {
  return (
    <Fragment>
      <Meta />
      <SectionIntro />
      <SectionSummary />
      <SectionHowItWorks />
      <SectionServices />
      <SectionCTA />
      <SectionPartners />
    </Fragment>
  )
}

export default HomePage
