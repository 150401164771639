import prefersReducedMotion from '~/utils/prefersReducedMotion'

const reduceMotion = prefersReducedMotion
  ? {
    initial: {},
    transition: {},
  }
  : {}

export const title = {
  initial: { y: '50vh' },
  animate: { y: 0 },
  transition: { type: 'spring', duration: 1, delay: 2 },
  ...reduceMotion,
}

export const revealBackground = {
  initial: { opacity: 1 },
  animate: { opacity: 0 },
  transition: { ease: 'easeInOut', duration: 0.75, delay: 4 },
  ...reduceMotion,
}

export const revealBlob = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: 'easeInOut', duration: 0.75, delay: 4 },
  ...reduceMotion,
}

export const feature1 = {
  initial: { y: 100, opacity: 0, scale: 0.5 },
  animate: { y: 0, opacity: 1, scale: 1 },
  transition: { type: 'spring', bounce: 0.5, delay: 4 },
  ...reduceMotion,
}

export const feature2 = {
  initial: feature1.initial,
  animate: feature1.animate,
  transition: { ...feature1.transition, delay: 4.25 },
  ...reduceMotion,
}

export const feature3 = {
  initial: feature1.initial,
  animate: feature1.animate,
  transition: { ...feature1.transition, delay: 4.5 },
  ...reduceMotion,
}


export const buttonContact = {
  initial: { scale: 0.5, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  transition: { type: 'spring', bounce: 0.75, delay: 5 },
  ...reduceMotion,
}

export const buttonLearnMore = {
  initial: { scale: 0.5, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  transition: { type: 'spring', bounce: 0.75, delay: 5.25 },
  ...reduceMotion,
}
