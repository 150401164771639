import { css } from '@emotion/react'

import colors from '~/styles/colors'
import boxShadow from '~/styles/mixins/boxShadow'

const SIZE = '10rem'

export const mockupPayPalCircle = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.paypalYellow};
  padding: 1rem;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 100%;
  ${boxShadow};
  pointer-events: none;
  user-select: none;
`

export const iconShieldCheckmark = css`
  position: absolute;
  top: 0rem;
  right: -0.7rem;
  color: ${colors.green};
  font-size: 3.5rem;
`

export const imageLogoPaypal = css`
  width: 90%;
`
