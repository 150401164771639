import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'

export const mockupNotificationSales = css`
  display: flex;
  flex-direction: column;
  width: 14rem;
  border-radius: ${borderRadius};
  border: 0.1rem solid ${transparentize(0.85, colors.white)};
  background: ${colors.backgroundElement};
  color: ${colors.textBlack};
  padding: 1.5rem 1.25rem;
  ${boxShadow};
  pointer-events: none;
  user-select: none;

  > small {
    color: ${colors.textGray};
  }
`

export const salesValue = css`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
`

export const salesIncreasePercentage = css`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  font-size: 1rem;
  color: ${colors.green};
  font-weight: 600;
`

export const iconArrowUp = css`
  color: ${colors.green};
  margin-left: 0.5rem;
  font-size: 1rem;
`
