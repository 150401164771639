import React, { useState } from 'react'

import PropTypes from 'prop-types'

import AnimationTextPopIn from '~/components/Animations/TextPopIn'
import ScrollReveal from '~/components/ScrollReveal'
import SectionTitle from '~/components/SectionTitle'

/**
 * <Describe the AnimatedSectionTitle component here>
 *
 * @component
 * @usage import AnimatedSectionTitle from '~/components/AnimatedSectionTitle'
 * @example
 * <Add an example of how AnimatedSectionTitle is used here>
 */
const AnimatedSectionTitle = ({ children, code, ...extra }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <ScrollReveal
      duration={0}
      direction={undefined}
      onVisibilityChange={boolVal => setIsVisible(boolVal)}
    >
      <SectionTitle code={code} {...extra}>
        <AnimationTextPopIn isVisible={isVisible}>
          {children}
        </AnimationTextPopIn>
      </SectionTitle>
    </ScrollReveal>
  )
}

AnimatedSectionTitle.propTypes = {
  code: PropTypes.string,
}

AnimatedSectionTitle.defaultProps = {
  code: '',
}

export default AnimatedSectionTitle
