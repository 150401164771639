import { css } from '@emotion/react'
import { lighten } from 'polished'

import colors from '~/styles/colors'
import { borderRadius } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'

export const mockupPagesIndexed = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11rem;
  border-radius: ${borderRadius};
  background: linear-gradient(90deg, ${colors.purple} 0%, ${lighten(0.1, colors.purple)} 100%);
  color: ${colors.white};
  padding: 1.5rem 1.25rem;
  font-size: 0.85rem;
  ${boxShadow};
  pointer-events: none;
  user-select: none;
`

export const iconCheckmark = css`
  color: ${colors.green};
  font-size: 1.25rem;
`