import React, { useState, useEffect } from 'react'

import Icon from '~/components/Icon'

import DoughnutChart from './DoughnutChart'

import * as style from './styles'

const generateRandomUserRetention = (min, max) => {
  return Math.floor(min + Math.random() * (max - min))
}

const UserRetentionDoughnut = () => {
  const [userRetention, setUserRetention] = useState(0)

  useEffect(() => {
    setUserRetention(generateRandomUserRetention(60, 90))
  }, [])

  return (
    <div css={style.mockupRetention}>
      <div css={style.headerBar}>
        <div />
        <Icon icon='info' css={style.iconInfo} />
      </div>
      <div css={style.chartContainer}>
        <span css={style.chartValue}>
          {userRetention}%
        </span>
        <DoughnutChart value={userRetention} />
      </div>
      <div css={style.cardMeta}>
        <span css={style.cardTitle}>
          User Retention
        </span>
        <small css={style.cardSubtitle}>
          This month
        </small>
      </div>
    </div>
  )
}

export default UserRetentionDoughnut
