import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const ctaContainer = css`
  position: relative;
  background: ${colors.backgroundDivzGradient};
  overflow: hidden;
`

export const cta = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0 !important;
  max-width: 80rem;
  margin: auto;

  * {
    margin: 0;
  }

  ${mqMax.lg} {
    flex-direction: column;
    text-align: center;
  };
`

export const ctaTitle = css`
  margin: 0;
  font-size: 3rem;
`

export const ctaSubtitle = css`
  font-size: 1.5rem;
  font-weight: 300;
`

export const ctaButton = css`
  font-size: 1.25rem;
  padding: 1rem 5rem;

  ${mqMax.lg} {
    margin-top: 2rem;
  };
`
