const scrollTo = (id, options = {}) => {
  const element = document.getElementById(id)

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
      ...options,
    })
  } else {
    console.error('[ERROR] Element to scroll to not found.')
  }
}

export default scrollTo
