/* eslint-disable react/no-array-index-key */
import React from 'react'

import Image from '~/components/Image'

import * as style from './styles'

const RECIPES = [
  {
    image: 'foodPlateChickenSalad',
    name: 'Deconstructed Chicken Salad',
    calories: 180,
    time: 5,
    servings: 3,
  },
  {
    image: 'foodPlateSalmon',
    name: 'Grilled Garden Salmon',
    calories: 120,
    time: 15,
    servings: 2,
  },
  {
    image: 'foodPlateChickenCurry',
    name: 'Chicken Curry with Rice',
    calories: 210,
    time: 25,
    servings: 3,
  },
  {
    image: 'foodPlateSkewers',
    name: 'Buttery Chicken Skewers',
    calories: 180,
    time: 15,
    servings: 4,
  },
  {
    image: 'foodPlateChickenCurry',
    name: 'Indian Curry Chicken',
    calories: 180,
    time: 5,
    servings: 3,
  },
]

const MockupLaptopApp = () => {
  return (
    <div css={style.mockupLaptopApp}>
      <div css={style.appContainer}>
        <span css={style.appTitle}>
          Easy & Delicious Recipes
        </span>
        <p css={style.appDescription}>
          Find and offer regular cooking motivation.
          Find plans, cooks, recordings, and how-tos dependent on the food you love and the companions you follow.
          You&apos;re certain to discover something that will fulfill your hunger here!
        </p>
        <div css={style.dishesContainer}>
          {
            RECIPES.map((recipe, index) => (
              <div key={`recipe-${recipe.name}-${index}`} css={style.dishContainer}>
                <Image image={recipe.image} css={style.dishImage} />
                <span css={style.dishName}>{recipe.name}</span>
                <small css={style.dishCalories}>{recipe.calories} calories</small>
                <hr />
                <div css={style.dishDetails}>
                  <div css={style.dishDetail}>
                    <small>Time</small>
                    <b>{recipe.time} mins</b>
                  </div>
                  <div css={style.dishDetail}>
                    <small>Servings</small>
                    <b>{recipe.servings} people</b>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Image image='frameMacbook' />
    </div>
  )
}

export default MockupLaptopApp
