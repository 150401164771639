import React from 'react'

import Icon from '~/components/Icon'

import * as style from './styles'

const MockupNotificationPagesIndexed = () => {
  return (
    <div css={style.mockupPagesIndexed}>
      All Pages Indexed!
      <Icon icon='checkmark' css={style.iconCheckmark} />
    </div>
  )
}

export default MockupNotificationPagesIndexed
