import React, { useState, useEffect } from 'react'

import Button from '~/components/Button'
import Icon from '~/components/Icon'
import Image from '~/components/Image'
import colors from '~/styles/colors'

import names from './names.json'

import * as style from './styles'

const generateRandom4Digits = () => {
  return Math.floor(1000 + Math.random() * 9000)
}

const getName = () => {
  const firstNames = names.first_names
  const lastNames = names.last_names
  const randomFirstNameIndex = Math.floor(Math.random() * firstNames.length)
  const randomLastNameIndex = Math.floor(Math.random() * lastNames.length)
  return `${firstNames[randomFirstNameIndex] } ${ lastNames[randomLastNameIndex]}`
}

const CreditCard = () => {
  const [creditCardNumbers, setCreditCardNumbers] = useState([])
  const [creditCardName, setCreditCardName] = useState('')
  const date = new Date()
  let month = date.getMonth() + 1
  const year = (date.getFullYear() + 1).toString().substring(2)

  if (month < 10) {
    month = `0${month}`
  }

  const rollNewCreditCard = () => {
    setCreditCardNumbers(Array(4).fill(0).map(() => generateRandom4Digits()))
    setCreditCardName(getName())
  }

  useEffect(() => {
    rollNewCreditCard()
  }, [])

  return (
    <div css={style.mockupCreditCard}>
      <Button
        aria-label='Shuffle Card Info'
        css={style.refreshButton}
        isSolid
        color='divzBlue'
        onClick={rollNewCreditCard}
      >
        <Icon
          icon='shuffle'
          animatedProps={{
            trigger: 'loop',
            delay: '2000',
            colors: `primary:${colors.white},secondary:${colors.white}`,
          }}
        />
      </Button>
      <div css={style.creditCardHeader}>
        <Icon icon='simChip' css={style.iconSimChip} />
        <Image image='logoVisa' css={style.imageLogoVisa} />
      </div>
      <div css={style.creditCardNumber}>
        {
          creditCardNumbers.map((number) => (
            <span key={`cc_number-${number}`}>{number}</span>
          ))
        }
      </div>
      <div css={style.creditCardInfo}>
        <div css={style.creditCardInfoBlock}>
          <small>CARD HOLDER</small>
          <span>{creditCardName}</span>
        </div>
        <div css={style.creditCardInfoBlock}>
          <small>VALID THRU</small>
          <span>{month}/{year}</span>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
