import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'

export default css`
  background: linear-gradient(120deg, ${colors.divzBlue} 25%, ${colors.divzGreen} 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0.1rem 0.1rem 0.3rem ${transparentize(0.75, colors.black)});
`