import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'

export const mockupSeo = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 17.5rem;
  height: 20rem;
  border-radius: ${borderRadius};
  border: 0.1rem solid ${transparentize(0.85, colors.white)};
  background-color: ${colors.backgroundElement};
  color: ${colors.textBlack};
  ${boxShadow};
`

export const valuesContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  padding-bottom: 0;
`

export const increaseValue = css`
  position: relative;
  font-size: 4rem;
  font-weight: 300;
`

export const percentageSymbol = css`
  font-size: 2rem;
  position: absolute;
  top: 25%;
`

export const valueLabel = css`
  color: ${colors.textGray};
  font-size: 1.1rem;
  margin-top: -1rem;
`

export const iconArrowUp = css`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10rem;
  color: ${colors.green};
  opacity: 0.1;
`

export const chartContainer = css`
  position: absolute;
  height: 90%;
  width: calc(100% + 0.7rem);
  bottom: 0;
  left: -0.3rem;
`
