import React from 'react'

import MockupBlob from '~/components/MockupBlob'
import ScrollReveal from '~/components/ScrollReveal'

import MockupAverageHitChart from '../AverageHitChart'
import MockupWrapper from '../MockupWrapper'
// todo: Mockups need CLEANUP
import MockupNotificationPagesIndexed from '../NotificationPagesIndexed'
import MockupUserRetentionDoughnut from '../UserRetentionDoughnut'

import * as style from './styles'

const MOCKUP_WIDTH = 545
const SCALES = {
  ALL: 1,
}

const MockupSeo = ({ rtl, ...props }) => {
  const revealDirection = rtl ? 'right' : 'left'

  return (
    <MockupWrapper width={MOCKUP_WIDTH} scales={SCALES}>
      {
        (scales) => (
          <div data-nosnippet css={style.mockSeo(`${MOCKUP_WIDTH}px`)} {...props}>
            <ScrollReveal direction={undefined}>
              <MockupBlob id='2' />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} css={style.mockupUserRetention(scales.ALL)}>
              <MockupUserRetentionDoughnut />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={200} css={style.mockupAverageHits(scales.ALL)}>
              <MockupAverageHitChart />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={400} css={style.mockupPagesIndexed(scales.ALL)}>
              <MockupNotificationPagesIndexed />
            </ScrollReveal>
          </div>
        )
      }
    </MockupWrapper>
  )
}

export default MockupSeo
