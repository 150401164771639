import React from 'react'

import Icon from '~/components/Icon'
import Image from '~/components/Image'

import * as style from './styles'

const MockupPhoneApp = () => {
  return (
    <div css={style.mockupPhoneApp}>
      <div css={style.appContainer}>
        <div css={style.appHeader}>
          <Icon icon='chevronLeft' />
          Rico
          <Icon icon='shoppingBag' />
        </div>
        <div css={style.productSubtitle}>
          Modern Beige Lounge Chair
        </div>
        <div css={style.productShowcase}>
          <Image image='furnitureArmchair' />
          <Image image='furnitureChair' />
          <Image image='furnitureCouch' />
        </div>
        <div css={style.productDetailsContainer}>
          <div css={style.productDetailsOutter}>
            <Image image='furnitureChair' />
            <Icon icon='heart' css={style.iconFavoriteProduct} />
            <div css={style.productDetails}>
             $ 2,509.00
              <span css={style.productTitle}>
                Rico Lounge Chair
              </span>
              <div css={style.productReview}>
                <Icon icon='star' /> <span>4.7</span> <small>(57 reviews)</small>
              </div>
            </div>
          </div>
          <div css={style.productDescription}>
            <b>SKU: FL-100273103</b>
            <p>
              The Rico series welcomes curves and volumes into a classic design defined by soft lines and an embracing expression.
              Contrasting the sturdiness of the frame, the armchair has an upholstery of timeless, yet easeful fabrics including Bouclé, which is woven of uneven yarn to attain the rich, loopy texture of the surface or rich and soft brushed chenille.
            </p>
          </div>
        </div>
        <div css={style.appFooter}>
          <Icon icon='home' />
          <Icon icon='search' />
          <Icon icon='scan' />
          <Icon icon='heart' />
          <Icon icon='qrCode' />
        </div>
      </div>
      <Image image='frameiPhone' />
    </div>
  )
}

export default MockupPhoneApp
