import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import Particles from 'react-tsparticles'
import { loadSlim } from 'tsparticles-slim'

import * as particleParameters from './particles'

import * as style from './styles'

/**
 * <Describe the ParticlesBackground component here>
 *
 * @component
 * @usage import ParticlesBackground from '~/components/ParticlesBackground'
 * @example
 * <Add an example of how ParticlesBackground is used here>
 */
const ParticlesBackground = ({ id, width, height, withMask, type, ...extra }) => {
  const particlesInit = useCallback(async engine => {
    await loadSlim(engine)
  }, [])

  const particlesLoaded = useCallback(async container => {
    await console.log(container)
  }, [])

  return (
    <div css={style.particlesBackground} {...extra}>
      <Particles
        id={id || 'tsparticles'}
        className='tsparticles'
        width={width}
        height={height}
        options={particleParameters[type]}
        init={particlesInit}
        loaded={particlesLoaded}
      />
      {
        withMask && <div css={style.particlesMask} />
      }
    </div>
  )
}

ParticlesBackground.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withMask: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(particleParameters)),
}

ParticlesBackground.defaultProps = {
  width: '100%',
  height: '100vh',
  withMask: true,
  type: 'particlesCalm',
}

export default ParticlesBackground
