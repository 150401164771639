import React from 'react'

import Icon from '~/components/Icon'
import Image from '~/components/Image'

import * as style from './styles'

const MockupPayPalCircle = () => {
  return (
    <div css={style.mockupPayPalCircle}>
      <Icon icon='shieldCheckmark' css={style.iconShieldCheckmark} />
      <Image image='logoPaypal' css={style.imageLogoPaypal} />
    </div>
  )
}

export default MockupPayPalCircle
