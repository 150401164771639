import { css } from '@emotion/react'
import { lighten } from 'polished'

import colors from '~/styles/colors'
import { borderRadius, transitionBounce, zIndexes } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const mockSupport = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28rem;
  width: 34rem;

  ${mqMax.md} {
    flex-wrap: wrap;
    width: unset;
    height: unset;
  }
`

export const talkingPointsContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`

export const talkingPointContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  ${mqMax.md} {
    position: initial;
  }
`

export const talkingPoint = color => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 9rem;
  padding: 0.5rem;
  ${boxShadow};
  border-radius: ${borderRadius};
  background: ${lighten(0.1, colors[color])};
  color: ${colors.textGray};
  font-weight: 500;
  ${transitionBounce};
  
  #divz-icon { 
    font-size: 2.25rem;
  }

  > span {
    margin-top: 0.25rem;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
`
