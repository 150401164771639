import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius, zIndexes } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const stepsContainer = css`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
`

export const stepContainer = css`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 13rem;
  height: 20rem;
  border-radius: ${borderRadius};
  border: 0.1rem solid ${transparentize(0.85, colors.white)};
  background: ${colors.backgroundElement};
  color: ${colors.textBlack};
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 1rem;
  ${boxShadow};
  
  > #divz-icon {
    color: ${colors.divzBlue};
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    filter: drop-shadow(0rem 0rem 0.95rem ${transparentize(0.2, colors.divzBlue)});
  }

  > p {
    font-size: 1.1rem;
    font-weight: normal;
    color: ${colors.textGray};
  }

  ${mqMax.lg} {
    width: 85vw;
    height: auto;
    margin: 1rem 0;
  }

  ${mqMax.sm} {
    width: 80%;
  }
`

export const step = css`
  position: absolute;
  top: -4rem;
  left: 0;
  font-size: 10rem;
  color: ${transparentize(0.85, colors.gray)};
  z-index: ${zIndexes.neutral};
  pointer-events: none;
  user-select: none;
`
