/* eslint-disable react/no-array-index-key */
import React from 'react'

import AnimatedSectionTitle from '~/components/AnimatedSectionTitle'
import Icon from '~/components/Icon'
import ScrollReveal from '~/components/ScrollReveal'
import MockupApps from '~/mockups/MockupApps'
import MockupPayment from '~/mockups/MockupPayment'
import MockupSeo from '~/mockups/MockupSeo'
import MockupSupport from '~/mockups/MockupSupport'

import * as style from './styles'

// todo: Abstract all data into fixtures
const SERVICES = [
  {
    title: 'Building your Next Big Idea',
    services: [
      {
        icon: 'web',
        title: 'Web and Cloud Applications',
        description: 'Host your next portfolio, application, or exposition online! This can be anything from function applications to static websites.',
      },
      {
        icon: 'devices',
        iconProps: { animatedProps: { trigger: 'morph' } },
        title: 'Desktop and Mobile Apps',
        description: 'Build your next app idea for any desktop or mobile platform you\'d like!',
      },
      {
        icon: 'cloud',
        title: 'Saas',
        description: 'Make it easy to build and scale your next online service business!',
      },
    ],
    Mockup: MockupApps,
  },
  {
    title: 'Improving Exposure and Gathering Data',
    services: [
      {
        icon: 'search',
        title: 'Search Engine Optimization',
        description: 'Increase traffic to your website and dominate search result indexes by pushing your website to the top.',
      },
      {
        icon: 'dotChart',
        iconProps: { animatedProps: { trigger: 'morph' } },
        title: 'Analytics',
        description: 'Get valuable insight into how users are behaving on your website and figure out what works and what doesn\'t.',
      },
    ],
    Mockup: MockupSeo,
  },
  {
    title: 'Getting Paid',
    services: [
      {
        icon: 'creditCard',
        title: 'Payment Services',
        description: 'Make it easy for you to get paid and for your users to pay you with various payment options.',
      },
    ],
    Mockup: MockupPayment,
  },
  {
    title: 'Support and Guidance',
    services: [
      {
        icon: 'packages',
        title: 'Libraries',
        description: 'Need a package or library built for your development team? We can help you build whatever pieces you\'re missing with beautiful results and documentation.',
      },
      {
        icon: 'bandage',
        iconProps: { animatedProps: { trigger: 'morph' } },
        title: 'Code Review and System Improvement',
        description: 'We can offer you another set of eyes and review your current system and underlying code; After which suggestions can be made and improvements can be implemented.',
      },
    ],
    Mockup: MockupSupport,
  },
]

const Service = ({ title, services, rtl, Mockup }) => (
  <div css={style.serviceContainer(rtl)}>
    {
      Mockup && <Mockup css={style.serviceMockups(rtl)} rtl={rtl} />
    }
    <ScrollReveal direction={rtl ? 'left' : 'right'} css={style.serviceWrapper}>
      <div>
        <h3 css={style.serviceContainerTitle}>
          {title}
        </h3>
        <div>
          {
            services.map(({ icon, iconProps = {}, title, description }) => (
              <div key={`service_card-${title}`} css={style.service}>
                <div>
                  <Icon icon={icon} css={style.serviceIcon} {...iconProps} />
                </div>
                <div css={style.serviceDescriptionWrapper}>
                  <h4 css={style.serviceTitle}>
                    {title}
                  </h4>
                  <span css={style.serviceDescription}>
                    {description}
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </ScrollReveal>
  </div>
)

const SectionServices = () => {
  return (
    <section css={style.services}>
      <AnimatedSectionTitle code='offered_services'>
        We Offer a Broad Range of Software Development Services
      </AnimatedSectionTitle>
      {
        SERVICES.map((service, index) => (
          <Service
            key={`service=${index}`}
            {...service}
            rtl={index % 2 === 0}
          />
        ))
      }
    </section>
  )
}

export default SectionServices
