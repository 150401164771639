import React from 'react'

import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the SectionTitle component here>
 *
 * @component
 * @usage import SectionTitle from '~/components/SectionTitle'
 * @example
 * <Add an example of how SectionTitle is used here>
 */
const SectionTitle = ({ code, children, ...extra }) => {
  return (
    <div css={style.sectionTitleContainer}>
      {code && (
        <span css={style.sectionCode}>
          {'<'} {code} {'>'}
        </span>
      )}
      <h2 css={style.sectionTitle} {...extra}>
        {children}
      </h2>
    </div>
  )
}

SectionTitle.propTypes = {
  code: PropTypes.string,
}

SectionTitle.defaultProps = {
  code: '',
}

export default SectionTitle
