import PropTypes from 'prop-types'

import useWindowSize from '~/hooks/useWindowSize'

const MockupWrapper = ({ children, width, scales }) => {
  const size = useWindowSize()
  const newScales = { ...scales }

  if (size.width < width) {
    Object.keys(scales).forEach(scaleKey => {
      const scaleInitialValue = scales[scaleKey]
      let scaleNewValue = size.width * scaleInitialValue / width
      scaleNewValue = scaleNewValue - scaleNewValue * 0.10 // ? Scale down an extra 10%

      newScales[scaleKey] = scaleNewValue
    })
  }

  return children(newScales)
}

MockupWrapper.propTypes = {
  width: PropTypes.number.isRequired,
  scales: PropTypes.object.isRequired,
}

MockupWrapper.defaultProps = {
  width: 0,
  scales: {},
}

export default MockupWrapper
