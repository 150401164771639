import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { transition } from '~/styles/constants'

export const partnersContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    margin: 0 2rem;
  }
`

export const partnerImage = css`
  font-size: 7rem;
  filter: grayscale(1) opacity(0.5);
  ${transition};

  &:hover {
    filter: none;
  }
`

export const tippyTooltip = css`
  font-family: inherit;
  background: ${colors.backgroundDivzGradient};
  color: ${colors.textBlack};
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
`
