import React from 'react'

import PropTypes from 'prop-types'

import Image from '~/components/Image'

import * as style from './styles'

/**
 * <Describe the MockupBlob component here>
 *
 * @component
 * @usage import MockupBlob from '~/components/MockupBlob'
 * @example
 * <Add an example of how MockupBlob is used here>
 */
const MockupBlob = ({ id }) => {
  return (
    <div css={style.blob}>
      <Image image={`Blob${id}`} />
    </div>
  )
}

MockupBlob.propTypes = {
  id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
}

MockupBlob.defaultProps = {
  id: 1,
}

export default MockupBlob
