import React, { useState, useEffect } from 'react'

import Icon from '~/components/Icon'

import * as style from './styles'

const generateRandomSalesNumber = (min, max) => {
  return (min + Math.random() * (max - min)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const MockupNotificationSales = () => {
  const [sales, setSales] = useState(0)
  const [salesIncrease, setSalesIncrease] = useState(0)

  useEffect(() => {
    setSales(generateRandomSalesNumber(10000, 20000))
    setSalesIncrease(generateRandomSalesNumber(10, 20))
  }, [])

  return (
    <div css={style.mockupNotificationSales}>
      <small>Sales this month</small>
      <div css={style.salesValue}>
        ${sales}
        <span css={style.salesIncreasePercentage}>
          +{salesIncrease}%
          <Icon icon='arrowUp' css={style.iconArrowUp} />
        </span>
      </div>
    </div>
  )
}

export default MockupNotificationSales
