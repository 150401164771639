import React from 'react'

import MockupBlob from '~/components/MockupBlob'
import ScrollReveal from '~/components/ScrollReveal'

import MockupBitcoinAccepted from '../BitcoinAccepted'
import MockupCreditCard from '../CreditCard'
import MockupWrapper from '../MockupWrapper'
import MockupNotificationSales from '../NotificationSales'
import MockupPayPalCircle from '../PayPalCircle'

import * as style from './styles'

const MOCKUP_WIDTH = 545
const SCALES = {
  ALL: 1,
  PAYPAL: 0.75,
}

const MockupPayment = ({ rtl, ...props }) => {
  const revealDirection = rtl ? 'right' : 'left'

  return (
    <MockupWrapper width={MOCKUP_WIDTH} scales={SCALES}>
      {
        (scales) => (
          <div data-nosnippet css={style.mockPayment(`${MOCKUP_WIDTH}px`)} {...props}>
            <ScrollReveal direction={undefined}>
              <MockupBlob id='3' />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} css={style.mockupBitcoinAccepted(scales.ALL)}>
              <MockupBitcoinAccepted />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={200} css={style.mockupPaypalCircle(scales.PAYPAL)}>
              <MockupPayPalCircle />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={400} css={style.mockupCreditCard(scales.ALL)}>
              <MockupCreditCard />
            </ScrollReveal>
            <ScrollReveal direction={revealDirection} delay={600} css={style.mockupNotificationSales(scales.ALL)}>
              <MockupNotificationSales />
            </ScrollReveal>
          </div>
        )
      }
    </MockupWrapper>
  )
}

export default MockupPayment
