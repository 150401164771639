/* eslint-disable react/no-array-index-key */
import React from 'react'

import AnimatedSectionTitle from '~/components/AnimatedSectionTitle'
import Button from '~/components/Button'
import ParticlesBackground from '~/components/ParticlesBackground'
import ScrollReveal from '~/components/ScrollReveal'
import { Link, router } from '~/lib/router'

import * as style from './styles'

const Subtitle = () => (
  <h3 css={style.ctaSubtitle}>
    Or just say &apos;hi&apos;. Our lines are open!
  </h3>
)

const ButtonCta = () => (
  <Link title='Contact Us' href={router.routes.contact}>
    <Button isSolid css={style.ctaButton}>
      Contact Us
    </Button>
  </Link>
)

const SectionCTA = () => {
  return (
    <div css={style.ctaContainer}>
      <ParticlesBackground
        id='particles-cta'
        withMask={false}
        type='particlesHastey'
      />
      <div css={style.cta}>
        <div>
          <AnimatedSectionTitle css={style.ctaTitle}>
            Let&apos;s talk business.
          </AnimatedSectionTitle>
          <ScrollReveal>
            <Subtitle />
          </ScrollReveal>
        </div>
        <ScrollReveal direction='right'>
          <ButtonCta />
        </ScrollReveal>
      </div>
    </div>
  )
}

export default SectionCTA
