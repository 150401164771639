import { css } from '@emotion/react'

export const mockApps = width => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  width: 100%;
  max-width: ${width};
`

export const mockupLaptopApp = scale => css`
  position: absolute;
  pointer-events: none;

  > * {
    transform: scale(${scale});
  }
`

export const mockupPhoneApp = scale => css`
  position: absolute;
  pointer-events: none;

  > * {
    transform: scale(${scale}) translate(40rem, 10rem) rotate(5deg);
  }
`
