import colors from '~/styles/colors'

export const particlesCalm = {
  particles: {
    number: {
      value: 25,
      density: {
        enable: false,
        value_area: 100,
      },
    },
    color: {
      value: colors.divzBlue,
    },
    shape: {
      type: 'images',
      image: [
        { src: '/static/shapes/circle-blue.svg' },
        { src: '/static/shapes/circle-outline-blue.svg' },
        { src: '/static/shapes/triangle-blue.svg' },
        { src: '/static/shapes/triangle-outline-blue.svg' },
        { src: '/static/shapes/square-blue.svg' },
        { src: '/static/shapes/square-outline-blue.svg' },
        { src: '/static/shapes/circle-green.svg' },
        { src: '/static/shapes/circle-outline-green.svg' },
        { src: '/static/shapes/triangle-green.svg' },
        { src: '/static/shapes/triangle-outline-green.svg' },
        { src: '/static/shapes/square-green.svg' },
        { src: '/static/shapes/square-outline-green.svg' },
      ],
    },
    rotate: {
      value: 10,
      random: true,
      direction: 'clockwise',
      animation: {
        enable: true,
        speed: 1,
        sync: false,
      },
    },
    opacity: {
      value: 0.7,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 35,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 1688.7759530930634,
      color: '#23e14f',
      opacity: 0.015782952832645452,
      width: 0,
    },
    move: {
      enable: true,
      speed: 0.75,
      direction: 'top',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 236.7442924896818,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'repulse',
      },
      onclick: {
        enable: false,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  detectRetina: true,
  pauseOnBlur: true,
  fullScreen: { enable: false, zIndex: 0 },
}

export const particlesHastey = {
  particles: {
    number: {
      value: 25,
      density: {
        enable: false,
        value_area: 200,
      },
    },
    color: {
      value: colors.divzBlue,
    },
    shape: {
      type: 'images',
      image: [
        { src: '/static/shapes/circle-blue.svg' },
        { src: '/static/shapes/circle-outline-blue.svg' },
        { src: '/static/shapes/triangle-blue.svg' },
        { src: '/static/shapes/triangle-outline-blue.svg' },
        { src: '/static/shapes/square-blue.svg' },
        { src: '/static/shapes/square-outline-blue.svg' },
        { src: '/static/shapes/circle-green.svg' },
        { src: '/static/shapes/circle-outline-green.svg' },
        { src: '/static/shapes/triangle-green.svg' },
        { src: '/static/shapes/triangle-outline-green.svg' },
        { src: '/static/shapes/square-green.svg' },
        { src: '/static/shapes/square-outline-green.svg' },
      ],
    },
    rotate: {
      value: 10,
      random: true,
      direction: 'clockwise',
      animation: {
        enable: true,
        speed: 1,
        sync: false,
      },
    },
    opacity: {
      value: 0.1,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 30,
      random: true,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.01,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 1688.7759530930634,
      color: '#23e14f',
      opacity: 0.015782952832645452,
      width: 0,
    },
    move: {
      enable: true,
      speed: 2,
      direction: 'top',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 236.7442924896818,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'repulse',
      },
      onclick: {
        enable: false,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  detectRetina: true,
  pauseOnBlur: true,
  fullScreen: { enable: false, zIndex: 0 },
}