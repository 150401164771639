import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { zIndexes } from '~/styles/constants'

export const particlesBackground = css`
  .tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const particlesMask = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.backgroundParticles};
  z-index: ${zIndexes.neutral};
`
