import React from 'react'

import Button from '~/components/Button'
import Image from '~/components/Image'

import * as style from './styles'

const MockupBitcoinAccepted = () => {
  return (
    <div css={style.mockupBitcoinAccepted}>
      <Image image='logoBitcoin' css={style.imageLogoBitcoin} />
      <span css={style.bitcoinAcceptedTitle}>
        Accepted here!
      </span>
      <Button css={style.bitcoinAcceptedButton} color='bitcoinOrange'>
        Buy with Bitcoin
      </Button>
    </div>
  )
}

export default MockupBitcoinAccepted
