import React from 'react'

import { motion } from 'framer-motion'

import AnimationTextPopIn from '~/components/Animations/TextPopIn'
import Button from '~/components/Button'
import Icon from '~/components/Icon'
import MockupBlob from '~/components/MockupBlob'
import ParticlesBackground from '~/components/ParticlesBackground'
import { Link, router } from '~/lib/router'
import scrollTo from '~/lib/scrollTo'

import * as animation from './animations'
import * as style from './styles'

const Feature = ({ icon, title, ...extra }) => (
  <motion.div css={style.feature} {...extra}>
    <Icon
      icon={icon}
      animatedProps={{
        trigger: 'loop',
        delay: '2000',
        style: { width: '2.5em', height: '2.5em' },
      }}
    /> {title}
  </motion.div>
)

const onClickLearnMore = () => {
  scrollTo('section-how_it_works')
}

const SectionIntro = () => {
  return (
    <section css={style.sectionIntro}>
      <ParticlesBackground id='particles-intro' />
      <motion.div
        css={style.revealBackground}
        {...animation.revealBackground}
      />
      <motion.div
        css={style.blob}
        {...animation.revealBlob}
      >
        <MockupBlob id='8' />
      </motion.div>
      <div css={style.introTextContainer}>
        <h3 css={style.introTextSecondary}>
          <AnimationTextPopIn>
            Need software or applications?
          </AnimationTextPopIn>
        </h3>
        <div css={style.introTextPrimaryBoundries}>
          <motion.h3 css={style.introTextPrimary} {...animation.title}>
            We&apos;ve got your back.
          </motion.h3>
        </div>
        <div css={style.buttonsContainer}>
          <Link title='Contact Us' href={router.routes.contact}>
            <Button isSolid animation={animation.buttonContact}>
              Contact Us Now
            </Button>
          </Link>
          <Button
            color='divzGreen'
            css={style.buttonLearnMore}
            animation={animation.buttonLearnMore}
            onClick={onClickLearnMore}
          >
            Learn More <Icon icon='arrowDown' />
          </Button>
        </div>
      </div>
      <div css={style.featuresContainer}>
        <Feature icon='package' title='AIO Solutions' {...animation.feature1} />
        <Feature icon='diamond' title='Beautiful Designs' {...animation.feature2} />
        <Feature icon='glasses' title='Quality Code' {...animation.feature3} />
      </div>
    </section>
  )
}

export default SectionIntro
