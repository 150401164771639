import React from 'react'

import AnimatedSectionTitle from '~/components/AnimatedSectionTitle'
import Icon from '~/components/Icon'
import ScrollReveal from '~/components/ScrollReveal'

import * as style from './styles'

const Step = ({ step, icon, title, children, ...extra }) => (
  <div css={style.stepContainer} {...extra}>
    <span css={style.step}>{step}</span>
    <Icon
      icon={icon}
      animatedProps={{
        style: { width: '4.25em', height: '4.25em' },
      }}
    /> {title}
    <p>
      {children}
    </p>
  </div>
)

const SectionHowItWorks = () => {
  return (
    <section id='section-how_it_works'>
      <AnimatedSectionTitle code='about_process'>
        A Process that gets Results Fast
      </AnimatedSectionTitle>
      <div css={style.stepsContainer}>
        <ScrollReveal>
          <Step step={1} icon='flag' title='Initiate'>
            We sit down and define the goals of your upcoming project.
            We&apos;ll clearly layout the most important bits and pieces.
          </Step>
          <Step step={2} icon='plan' title='Wireframe'>
            We make a technical blueprint of what your project should look like and how it should behave.
            We&apos;ll review it together one more time!
          </Step>
          <Step step={3} icon='build' title='Build'>
            Our team starts working tirelessly to start achieveing milestones.
            You&apos;ll be updated along the way!
          </Step>
          <Step step={4} icon='refine' title='Refine'>
            We finalize and deliver your polished product!
            We&apos;ll always be around in case you need anymore help!
          </Step>
        </ScrollReveal>
      </div>
    </section>
  )
}

export default SectionHowItWorks
