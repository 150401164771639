import { css } from '@emotion/react'

export const mockSeo = width => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
  width: 100%;
  max-width: ${width};
`

export const mockupUserRetention = scale => css`
  position: absolute;

  > * {
    transform: scale(${scale}) translate(-7rem, -4rem);
  }
`

export const mockupAverageHits = scale => css`
  position: absolute;

  > * {
    transform: scale(${scale}) translate(8rem, 1.5rem);
  }
`

export const mockupPagesIndexed = scale => css`
  position: absolute;

  > * {
    transform: scale(${scale}) translate(10rem, -12rem);
  }
`
