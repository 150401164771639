import React from 'react'

import Icon from '~/components/Icon'
import MockupBlob from '~/components/MockupBlob'
import ScrollReveal from '~/components/ScrollReveal'
import colors from '~/styles/colors'

import * as style from './styles'

// todo: Organize data:
const TALKING_POINTS = [
  {
    icon: 'notebook',
    animatedProps: { trigger: 'morph' },
    label: 'Knowledge Transfer',
    color: 'blue',
  },
  {
    icon: 'database',
    label: 'Data Organization',
    color: 'red',
  },
  {
    icon: 'git',
    label: 'Proper Tool Usage',
    color: 'orange',
  },
  {
    icon: 'messageReport',
    label: 'Code Review',
    color: 'green',
  },
  {
    icon: 'speedometer',
    label: 'Increased Performance',
    color: 'purple',
  },
  // {
  //   icon: 'recycle',
  //   label: 'Code Reusability',
  //   color: 'yellow',
  // },
  {
    icon: 'circleCheck',
    label: 'Best Practices',
    color: 'divzGreen',
  },
]

const TalkingPoint = ({ label, color, icon, animatedProps = {} }) => (
  <div
    key={`talking_point-${label}`}
    css={style.talkingPoint(color)}
  >
    <Icon
      icon={icon}
      animatedProps={{
        colors: `primary:${colors.white},secondary:${colors.white}`,
        ...animatedProps,
      }}
    />
    <span>{label}</span>
  </div>
)

const MockupSupport = ({ rtl, ...props }) => {
  const revealDirection = rtl ? 'right' : 'left'

  return (
    <div data-nosnippet css={style.mockSupport} {...props}>
      <ScrollReveal direction={undefined}>
        <MockupBlob id='4' />
      </ScrollReveal>
      <div css={style.talkingPointsContainer}>
        {
          TALKING_POINTS.map((point, index) => (
            <ScrollReveal
              key={`talking_point-${point.label}`}
              direction={revealDirection}
              delay={150 * index}
              css={style.talkingPointContainer}
            >
              <TalkingPoint {...point} />
            </ScrollReveal>
          ))
        }
      </div>
    </div>
  )
}

export default MockupSupport
