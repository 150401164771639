import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius } from '~/styles/constants'
import boxShadow from '~/styles/mixins/boxShadow'


export const mockupBitcoinAccepted = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  border: 0.1rem solid ${transparentize(0.85, colors.white)};
  background: ${colors.backgroundElement};
  color: ${colors.textBlack};
  padding: 1rem;
  width: 15rem;
  height: 16.5rem;
  ${boxShadow};
  pointer-events: none;
  user-select: none;
`

export const imageLogoBitcoin = css`
  margin-top: -1rem;
  width: 10rem;
  height: 10rem;
`

export const bitcoinAcceptedTitle = css`
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: -1rem;
  margin-bottom: 2rem;
`

export const bitcoinAcceptedButton = css`
  width: 90%;
  pointer-events: all;
`
