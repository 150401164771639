import React from 'react'

import { Fade } from 'react-awesome-reveal'

import prefersReducedMotion from '~/utils/prefersReducedMotion'

/**
 * <Describe the ScrollReveal component here>
 *
 * @component
 * @usage import ScrollReveal from '~/components/ScrollReveal'
 * @example
 * <Add an example of how ScrollReveal is used here>
 */
const ScrollReveal = ({ children, ...props }) => {
  if (prefersReducedMotion) {
    return (
      <Fade
        duration={0}
        triggerOnce
        fraction={0}
        {...props}
      >
        {children}
      </Fade>
    )
  }

  return (
    <Fade
      cascade={true}
      direction='up'
      duration={500}
      triggerOnce
      fraction={1}
      {...props}
    >
      {children}
    </Fade>
  )
}

ScrollReveal.propTypes = {
}

ScrollReveal.defaultProps = {
}

export default ScrollReveal
