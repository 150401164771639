/* eslint-disable react/no-array-index-key */
import React from 'react'

import { motion } from 'framer-motion'

export default ({ children, ...rest }) => {
  const words = children.split(' ')

  return words.map((word, index) => (
    <div
      key={children + index}
      style={{ display: 'inline-block', overflow: 'hidden' }}
    >
      <motion.div
        {...rest}
        style={{ display: 'inline-block', willChange: 'transform' }}
        custom={index}
      >
        {word + (index !== words.length - 1 ? '\u00A0' : '')}
      </motion.div>
    </div>
  ))
}
