import React, { useState, useEffect } from 'react'

import Icon from '~/components/Icon'

import AreaChart from './AreaChart'

import * as style from './styles'

const generateRandomHitsValue = (min, max) => {
  return Math.floor(min + Math.random() * (max - min))
}

const MockupAverageHitChart = () => {
  const [hits, setHits] = useState(0)

  useEffect(() => {
    setHits(generateRandomHitsValue(40, 200))
  }, [])

  return (
    <div css={style.mockupSeo}>
      <div css={style.valuesContainer}>
        <span css={style.increaseValue}>
          {hits}
          <span css={style.percentageSymbol}>
            %
          </span>
        </span>
        <span css={style.valueLabel}>
          Increase in average hits
        </span>
        <Icon icon='arrowUp' css={style.iconArrowUp} />
      </div>
      <div css={style.chartContainer}>
        <AreaChart />
      </div>
    </div>
  )
}

export default MockupAverageHitChart
