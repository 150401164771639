import { css } from '@emotion/react'

import colors from '~/styles/colors'
import { mqMax, mqMin } from '~/styles/mixins/mediaQueries'

export const services = css`
  overflow-x: hidden;
`

export const serviceContainer = rtl => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  max-width: 80rem; // todo: gather all instances of max-width
  margin-bottom: 10rem;
  ${rtl && 'flex-direction: row-reverse'};

  ${mqMax.lg} {
    flex-direction: column;
    margin-bottom: 7.5rem;
  }
`

export const serviceWrapper = css`
  ${mqMin.lg} {
    width: 50%;
  }
`

export const serviceDescriptionWrapper = css`
  width: 90%;
`

export const serviceMockups = rtl => css`
  ${
  rtl
    ? 'margin-left: 0rem;'
    : 'margin-right: 3rem;'
}

  ${mqMax.md} {
    margin: 0;
  }
`

export const serviceContainerTitle = css`
  font-size: 1.85rem;
  font-weight: 600;

  ${mqMax.lg} {
    text-align: center;
    margin-top: 5rem;
  }
`

export const service = css`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  ${mqMax.lg} {
    justify-content: center;
    margin-bottom: 2rem;
  }
`

export const serviceIcon = css`
  font-size: 3rem;
  margin-right: 2rem;
  color: ${colors.divzBlue};

  ${mqMax.lg} {
    width: 5rem;
  }
`

export const serviceTitle = css`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0rem;

  ${mqMax.lg} {
    margin-bottom: 0.5rem;
  }
`

export const serviceDescription = css`
  display: block;
  width: 95%;
  color: ${colors.textGray};

  ${mqMax.lg} {
    width: 100%;
  }
`
