import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { borderRadius, transition, zIndexes } from '~/styles/constants'

const COLOR_LIGHT_GRAY = transparentize(0.5, colors.gray)

export const mockupLaptopApp = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  pointer-events: none;
  user-select: none;
`

export const appContainer = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(/static/background-kitchen-spices.png);
  background-position: center;
  width: 77%;
  height: 84%;
  margin-top: -1.5rem;
  overflow: hidden;
`

export const appTitle = css`
  display: flex;
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  width: 25rem;
  text-align: center;
  line-height: 3.5rem;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem ${COLOR_LIGHT_GRAY});
`

export const appDescription = css`
  color: ${COLOR_LIGHT_GRAY};
  text-align: center;
  font-size: 0.45rem;
  width: 20rem;
  margin: 1.5rem 0;
`

export const dishesContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 115%;
  margin-left: 20%;
  margin-bottom: -1rem;
  margin-top: 3rem;
`

export const dishContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  background: ${transparentize(0.15, colors.backgroundDefault)};
  color: ${colors.textBlack};
  width: 8rem;
  height: 11rem;
  padding: 0 1rem;
  z-index: ${zIndexes.one};
  pointer-events: all;
  ${transition};

  &:hover {
    background: ${colors.backgroundDefault};
    margin-bottom: 1rem;
    margin-top: -1rem;
    
    img {
      transform: scale(1.2) rotate(45deg);
    }
  }

  > hr {
    padding: 0;
    margin: 0;
    width: 100%;
    margin: 0.5rem 0;
    border-top: 0.01rem dashed ${transparentize(0.85, colors.gray)};
  }
`

export const dishImage = css`
  width: 7rem;
  margin-top: -4.5rem;
  margin-bottom: 0.5rem;
  ${transition};
`

export const dishName = css`
  font-weight: 600;
  font-size: 0.8rem;
  width: 90%;
  text-align: center;
`

export const dishCalories = css`
  color: ${COLOR_LIGHT_GRAY};
  font-size: 0.5rem;
  margin: 0.2rem 0;
`

export const dishDetails = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const dishDetail = css`
  display: flex;
  flex-direction: column;

  > small {
    color: ${COLOR_LIGHT_GRAY};
    font-size: 0.45rem;
  }

  > b {
    font-size: 0.65rem;
  }
`
