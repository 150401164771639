import { css } from '@emotion/react'

import { mqMax } from '~/styles/mixins/mediaQueries'

export const mockPayment = width => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21rem;
  width: 100%;
  max-width: ${width};
`

export const mockupBitcoinAccepted = scale => css`
  position: absolute;

  > * {
    transform: scale(${scale}) translate(-5rem, 3rem);
  }
`

export const mockupPaypalCircle = scale => css`
  position: absolute;

  > * {
    transform: scale(${scale}) translate(-16rem, -8rem);
  }

  .lazyload-wrapper {
    justify-content: center;
  }
`

export const mockupCreditCard = scale => css`
  position: absolute;
  
  > * {
    transform: scale(${scale}) translate(7rem, -9rem) rotate(15deg);
  
    ${mqMax.lg} {
      transform: scale(${scale}) translate(5rem, -9rem) rotate(15deg);
    }
  }
`

export const mockupNotificationSales = scale => css`
  position: absolute;
  
  > * {
    transform: scale(${scale}) translate(9rem, 6rem);

    ${mqMax.lg} {
      transform: scale(${scale}) translate(8rem, 6rem);
    }
  }
`