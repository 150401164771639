import React from 'react'

import { lighten, darken } from 'polished'
import { Doughnut } from 'react-chartjs-2'

import colors from '~/styles/colors'

// const COLOR_ACTIVE = colors.orange
const COLOR_INACTIVE = lighten(0.55, colors.gray)

// todo: Abstract below
let width, height, gradient
const getLineGradient = (ctx, chartArea) => {
  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(-10, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(0, colors.transparent)
    gradient.addColorStop(0.1, darken(0.1, colors.orange))
    gradient.addColorStop(1, lighten(0.25, colors.orange))
  }

  return gradient
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: false,
    tooltip: false,
  },
  weight: 0.1,
  cutout: '85%',
}

const data = value => {
  return {
    labels: ['User Retention Rate'],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor(context) {
          const chart = context.chart
          const { ctx, chartArea } = chart

          if (!chartArea) { return null }

          return [
            getLineGradient(ctx, chartArea),
            COLOR_INACTIVE,
          ]
        },
        borderColor(context) {
          const chart = context.chart
          const { ctx, chartArea } = chart

          if (!chartArea) { return null }

          return [
            getLineGradient(ctx, chartArea),
            COLOR_INACTIVE,
          ]
        },
        borderWidth: 1,
      },
    ],
  }
}

const DoughnutChart = ({ value }) => (
  <Doughnut data={data(value)} options={options} />
)

export default DoughnutChart
